import React from "react";

import LayoutGeneral from "../components/LayoutGeneral";
import Section from "../components/Section";
import SectionRow from "../components/SectionRow";
import SVGCurve from "../components/SVGCurve";
import SEO from "../components/SEO";
import TextDiv from "../components/TextDiv";
import TextParagraph from "../components/TextParagraph";
import AppointmentDiv from "../components/AppointmentDiv";

function ConsultingPage() {
    return (
        <LayoutGeneral>
            <SEO
                keywords={[`Agile Psychiatry`, `Brian Nahas MD`, `Psychiatrist`, `Software Engineer`, `Consulting`]}
                title="Consulting"
                description="Brian Nahas, MD is available for consulting and speaking engagements regarding healthcare,
                mental health, and technology."
            />
            <Section shade="mid">
                <SectionRow>
                    <TextDiv>
                        <h2 className="text-3xl text-center mb-2">Consulting / Speaking</h2>
                        <TextParagraph className="mb-8">
                            Although his primary focus is clinical care at this time, Dr. Nahas is available on a limited basis for consulting
                            engagements at companies exploring the interface of technology and healthcare, mental health or otherwise.
                            He is also available for speaking engagements at organizations either exploring the above or solely
                            advocating for mental health.
                        </TextParagraph>
                        <TextParagraph className="mb-8">
                            If you are interested in inquiring about either of these services, please submit a request
                            via the form below. If you are a patient or directly associated with a patient, Dr. Nahas
                            will be unable to fulfill the request as it could affect the doctor-patient
                            relationship. Please do not submit any patient or medically related information below.
                        </TextParagraph>
                    </TextDiv>
                </SectionRow>
            </Section>
            <SVGCurve direction="right-slope" transition="mid-light"/>
            <Section shade="light">
                <SectionRow>
                <iframe
                    src="https://docs.google.com/forms/d/e/1FAIpQLSfWcv7Mn0kpbGfhByKACkh2yMDlu5UDpWbvsAz81AUmOZnxUw/viewform?embedded=true"
                    width="100%" height="1400" frameBorder="0" marginHeight="0" marginWidth="0">Loading…
                </iframe>
                </SectionRow>
            </Section>
            <SVGCurve direction="right-slope" transition="light-dark"/>
            <Section shade="dark">
                <SectionRow>
                    <AppointmentDiv/>
                </SectionRow>
            </Section>
            {/*<SVGCurve direction="right-slope" color="text-ap-dark-bg" bgColor="bg-gray-200"/>*/}
        </LayoutGeneral>
    );
}


export default ConsultingPage;
